import { useState } from "react";
import { IOption, IQuestion } from "../../../core/interfaces";
import { Typography } from "@mui/material";

export function QuestionMultiple(props: { question: IQuestion, onResponse?: Function }) {
  const { question, onResponse } = props;
  const setupSelect = question.responses;

  const [checkeds, setCheckeds] = useState<Array<string>>(setupSelect);
  const handleToggle = (option: IOption) => () => {
    const newChecked = [...checkeds];
    const index = newChecked.findIndex(id => id === option.id);
    if (index >= 0) {
      newChecked.splice(index, 1);
    } else {
      newChecked.push(option.id);
    }
    question.responses = newChecked;
    setCheckeds(newChecked);
    onResponse && onResponse();
  }
  const options = () => {
    return question.options.map(o => {
      const isSelected = checkeds.some(c => c === o.id);
      const classOption = 'app-option mb-2 ' + (isSelected ? 'app-option--selected' : '');
      return (
        <div className={classOption} key={o.id} onClick={handleToggle(o)}  >
          {o.name}
        </div>
      )
    })
  }
  return (
    <div className="flex">
      <div className="mr-2">
        <Typography variant="h6">
          {question.code}.
        </Typography>
      </div>
      <div>
        <div className="mb-6">
          <Typography variant="h6" >
            {question.name}
          </Typography>
        </div>
        <div >
          {options()}
        </div>
      </div>
    </div>
  )
}
