import { Typography } from "@mui/material";
import { useState } from "react";
import { IOption, IQuestion } from "../../../core/interfaces";


export function QuestionSingle(props: { question: IQuestion, onResponse?: Function }) {

  const { question, onResponse } = props;
  const setupSelect = question.responses.length ? question.responses[0] : '';
  const [selected, setSelected] = useState<string>(setupSelect);

  const options = () => {
    return question.options.map(o => {
      const isSelected = selected === o.id;
      const classOption = 'app-option mb-2 ' + (isSelected ? 'app-option--selected' : '');
      return (
        <div className={classOption} key={o.id} onClick={handleToggle(o)}  >
          {o.name}
        </div>
      )
    })
  }
  const handleToggle = (option: IOption) => () => {
    let optionSelected = option.id;
    if (option.id === selected) {
      optionSelected = '';
      question.responses = [];
    } else {
      question.responses = [option.id];
    }
    setSelected(optionSelected);
    onResponse && onResponse();
  }
  return (
    <div className="flex">
      <div className="mr-2">
        <Typography variant="h6">
          {question.code}.
        </Typography>
      </div>
      <div>
        <div className="mb-6">
          <Typography variant="h6" >
            {question.name}
          </Typography>
        </div>
        <div >
          {options()}
        </div>
      </div>
    </div>
  )
}
