export function findGetParameter(parameterName: string): string {
  var result = '',
    tmp = [];
  const route = window.location.search as string;
  console.log('route', route);

  route
    .substring(1)
    .split("&")
    .forEach(function (item) {
      tmp = item.split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
}