import { json } from "stream/consumers";

export type TypeKey = 'survey' | 'workspace';
export class StorageService {
  private static instance: StorageService;


  constructor() {
    if (StorageService.instance) {
      throw new Error("You can only create one instance!");
    }
    StorageService.instance = this;
  }
  static getInstance() {
    if (!StorageService.instance) {
      StorageService.instance = new StorageService();
    }

    return StorageService.instance;
  }
  public setLocal(key: TypeKey, value: any) {
    const data = {
      value
    };
    localStorage.setItem(key, JSON.stringify(data));
  }
  public getLocal(key: TypeKey) {
    const data = localStorage.getItem(key);
    if (data) {
      try {
        return JSON.parse(data).value;
      } catch (error) {
        return null;
      }
    }
    return null;
  }
}