import { Button } from "@mui/material";
import { useState } from "react";
import { QuestionTypeEnum } from "../../../core/enums";
import { IPage } from "../../../core/interfaces";
import { QuestionMultiple } from "../questions/QuestionMultiple";
import { QuestionSingle } from "../questions/QuestionSingle";
import { QuestionText } from "../questions/QuestionText";


interface PageProps {
  page: IPage;
  next?: Function;
  hiddenButtonNext?: boolean;
  viewCompress?: boolean;
}
export function Page(props: PageProps) {
  const { viewCompress, hiddenButtonNext, page, next } = props;
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const questions: Array<JSX.Element | null> = page.questions.map(q => {
    let elementQuestion = null;
    if (q.type === QuestionTypeEnum.Multiple) {
      elementQuestion = <QuestionMultiple question={q} onResponse={() => validate()} />;
    } else if (q.type === QuestionTypeEnum.Single) {
      elementQuestion = <QuestionSingle question={q} onResponse={() => validate()} />;
    } else if (q.type === QuestionTypeEnum.Text) {
      elementQuestion = <QuestionText question={q} onResponse={() => validate()} />;
    }
    const classContainer = q.hasIncosistency ? 'bg-has-inconsistency p-2' : 'p-2';
    return <div className={classContainer} key={q.id} >
      {elementQuestion}
    </div>;
  });
  const validate = () => {
    const isComplete = !page.questions.some(q => q.responses.length === 0);
    setIsComplete(isComplete);
  }
  const nextPage = () => {
    next && next();
    setIsComplete(false);
  }
  const classParent = viewCompress ? 'py-4 flex justify-start' : 'min-h-screen flex items-center justify-center';
  const buttonNext = hiddenButtonNext ? null : (<Button  onClick={() => nextPage()} variant="outlined" disabled={!isComplete} hidden={hiddenButtonNext}>
    Continuar
  </Button>);
  return (
    <div className={classParent}>
      <div className="p-2">
        {questions}
        <div>
          {buttonNext}
        </div>
      </div>
    </div>
  )
}
