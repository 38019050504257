import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ResponseSurveyDto } from "../../core/dtos/responses";
import { findGetParameter } from "../../core/helps";
import { SurveyService } from "../../core/services/SurveyService";
import { Content } from "./sections/Content";
import Loading from "./sections/Loading";
import Thank from "./sections/Thank";
import Welcome from "./sections/Welcome";
import { IResultDetail, IResultDetailConsistency } from "../../core/interfaces";
import { QuestionTypeEnum } from "../../core/enums";


type typeState = 'loading' | 'welcome' | 'survey' | 'open' | 'thank';
export default function Survey() {

  const { survey, idTenant } = useParams() as  {survey: string, idTenant: string};

  console.log('idTenant', idTenant);
  console.log('survey', survey);
  
  const _surveyService = SurveyService.getInstance();
  const [step, setStep] = useState<typeState>('loading');
  const [data, setData] = useState<ResponseSurveyDto>({
    survey: [],
    version: 0,
  });
  const action = findGetParameter('open');
  let result = findGetParameter('result');

  const isOpen = action === 'true';
  const hasResult = !!(result && !isNaN(+result));
  useEffect(function () {
    if (survey && step === 'loading') {
      _surveyService.setSurvey(idTenant, survey);
      loadSurvey();
    }
  }, [setStep, step])
  const loadSurvey = async () => {
    const requests: Array<Promise<any>> = [
      _surveyService.loadSurvey()
    ];
    if (hasResult) {
      requests.push(
        _surveyService.loadResult(+result),
        _surveyService.loadConsistency(+result),
      );
    }
    try {
      const response = await Promise.all(requests);
      const dataSurvey: ResponseSurveyDto = response[0];

      if (response[1]) {
        const data: Array<IResultDetail> = response[1];
        const dataConsistency: Array<IResultDetailConsistency> = response[2];
        dataSurvey.survey.forEach(page => {
          page.questions.forEach(q => {
            q.responses = data.filter(d => q.id == d.question_id).map(d => {
              return q.type === QuestionTypeEnum.Text ? d.response : d.option_id;
            });
            q.hasIncosistency = dataConsistency.some(d => d.question_id === q.id);
          })
        })
      } else {
        dataSurvey.survey.forEach(p => p.questions.forEach(q => q.responses = []));
      }
      handleDataSurver(dataSurvey);
    } catch (error) {
      console.log('error', error);

    }
  }
  function handleDataSurver(dataSurvey: ResponseSurveyDto) {

    if (isOpen) {
      setStep('survey');
    } else {
      setStep('welcome');
    }
    setData(dataSurvey);
  }
  const continueSurvey = () => {
    setStep('survey');
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successFunction, errorFunction);
    }
  }
  function successFunction(position: any) {
    var lat = position.coords.latitude;
    var long = position.coords.longitude;
    SurveyService.getInstance().setPositions(lat, long);
  }
  function errorFunction(error: any) {
    console.log('error', error);

  }
  function getState() {
    switch (step) {
      case 'welcome':
        return <Welcome onContinue={() => continueSurvey()} />;
      case 'thank':
        return <Thank />;
      case 'survey':
        return <Content version={data.version} survey={data.survey} open={isOpen} onFinish={() => setStep('thank')} />;
      default:
        return <Loading />;
    }
  }
  return (
    <div className="min-h-screen flex items-center justify-center">

      {getState()}

    </div>

  )
}
