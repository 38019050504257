import { Button, Container, Grid, Typography } from "@mui/material";
import { SurveyService } from "../../../core/services/SurveyService";


interface WelcomeProps {
  onContinue?: Function;
}
export default function Welcome(props: WelcomeProps) {

  const continueFlow = () => {
    props.onContinue && props.onContinue();



  }


  return (
    <div className="min-h-screen flex items-center">
      <Container >
        <Grid container columnSpacing={2} rowSpacing={2} alignItems="center">
          <Grid item lg={6} xs={12}>
            <div>
              <img className="max-100" src="/images/logo.png" alt="" />
            </div>
          </Grid>
          <Grid item lg={6} xs={12}>
            <div className="pb-8 sm:pb-0">
              <Typography className="text-center mb-2" variant="h2">
                Encuesta de cultura
              </Typography >
              <div>
                <p className="TextWrapper-sc-__sc-1f8vz90-0 JBdQy mb-2">
                  <span>En Laureate Perú estamos trabajando en el desarrollo de nuestra cultura Tinkuy, la cual representa la esencia cultural compartida y el punto de encuentro de nuestras instituciones. </span>
                  <br /><br />
                  <span>Tu participación es clave en este proceso por ello te invitamos a completar esta encuesta, la cual está compuesta por preguntas clave orientadas a entender tu percepción sobre la cultura de tu institución y de Laureate Perú. </span>
                  <br /><br />
                  <span>Completar esta encuesta solo te tomará 8 minutos. </span>
                  <br /><br />
                  <strong>¡Juntos seguiremos construyendo nuestra cultura Tinkuy! </strong>
                </p>
                <Button variant="contained" className=" invisible lg:visible" onClick={() => continueFlow()} >Empezar</Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container >
      <div className="fixed left-0 bottom-0 w-full flex justify-center bg-white p-2 visible lg:invisible" >
        <Button variant="contained" className="mt-2  w-full" onClick={() => continueFlow()}>Empezar</Button>
      </div>
    </div>

  )
}
