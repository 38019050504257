import axios from "axios";


import { configrationApp } from "../configs/configurationApp";
import { ResponseSurveyDto } from "../dtos/responses";
import { IPositionMap } from "../interfaces";
import { ResquestDataSendDTO } from "../dtos/requests";
import { HttpApi } from "../http-api";
import { StorageService } from "./Storage.service";


//let instance: AdminService;
export class SurveyService {
  private readonly BASE_API = configrationApp().apiApp + "/";
  private static instance: SurveyService;

  private _position: IPositionMap = {
    latitude: '',
    longitude: ''
  };

  constructor() {
    if (SurveyService.instance) {
      throw new Error("You can only create one instance!");
    }
    SurveyService.instance = this;
  }
  public setSurvey(idTenant: string, survey: string): void {
    StorageService.getInstance().setLocal('workspace', idTenant);
    StorageService.getInstance().setLocal('survey', survey);
  }
  static getInstance() {
    if (!SurveyService.instance) {
      SurveyService.instance = new SurveyService();
    }

    return SurveyService.instance;
  }
  public loadSurvey(): Promise<ResponseSurveyDto> {
    return axios.get(`${HttpApi.pathBusinessSurvey}/last`);
  }
  public sendData(data: ResquestDataSendDTO) {
    return axios.post(`${HttpApi.pathBusinessData}/save-data`, data);
  }
  public loadResult(result_id: number) {
    return axios.get(`${HttpApi.singleResultDetail}/result/${result_id}`);
  }
  public loadConsistency(result_id: number) {
    return axios.get(`${HttpApi.singleResultDetailConsistency}/result/${result_id}`);
  }
  public getPosition(): IPositionMap {
    return {
      ...this._position
    };
  }
  public setPositions(latitude: string, longitude: string) {
    this._position = {
      latitude,
      longitude
    }
  }
}