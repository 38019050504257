import { CircularProgress } from "@mui/material";



export default function Loading() {
  console.log('Loading');

  return (
    <div className="min-h-screen flex flex-col items-center justify-center w-full">
      <CircularProgress className="mb-4" size={50} />
      <div>
        Kualy.ai
      </div>
    </div>
  )
}
