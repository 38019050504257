import { TextField, Typography } from "@mui/material";
import { IQuestion } from "../../../core/interfaces";
import { useState } from "react";



export function QuestionText(props: { question: IQuestion, onResponse?: Function }) {
  const [value, setValue] = useState(
    props.question.responses[0] || ''
  )
  const setMessage = (value: string) => {
    console.log('change value', value);
    setValue(value);
    props.question.responses = value ? [value] : [];
    props.onResponse && props.onResponse();
  }

  return (
    <div className="flex w-full">
      <div className="mr-2">
        <Typography variant="h6">
          {props.question.code}.
        </Typography>
      </div>
      <div className="w-full">
        <div className="mb-6">
          <Typography variant="h6" >
            {props.question.name}
          </Typography>
        </div>
        <div className="mb-2 w-full ">
          <TextField
            id={props.question.id}
            label="Escribe aquí tu respuesta"
            value={value}
            className="w-full"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setMessage(event.target.value);
            }}
          />
        </div>
      </div>
    </div>
  )
}
