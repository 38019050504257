import { useState } from "react";
import { IPage } from "../../../core/interfaces";
import { Page } from "../../shareds/generals/Page";
import Survey from "../Survey";
import { ResquestDataSendDTO } from "../../../core/dtos/requests";
import { SurveyService } from "../../../core/services/SurveyService";

interface ContentProps {
  survey: Array<IPage>;
  open: boolean;
  version: number;
  onFinish: Function;
}

export function Content(props: ContentProps) {
  console.log('Content', props);
  const { open, survey, onFinish, version } = props;
  const [page, setPage] = useState<IPage>(
    survey[0]
  )
  const next = () => {
    let index = survey.findIndex(p => p.num === page.num);
    index++;
    const newPage = survey[index];
    if (newPage) {
      setPage(newPage);
    } else {
      const dataSend: ResquestDataSendDTO = {
        id: 'laureate',
        version,
        
        data: [],
        position: SurveyService.getInstance().getPosition()
      }

      survey.forEach(page => {
        page.questions.forEach(q => {
          if (q.responses.length) {
            dataSend.data.push({
              q: q.id,
              r: q.responses
            });
          }
        })
      });
      SurveyService.getInstance().sendData(dataSend).then(() => {
        onFinish();
      }, (error) => {
        alert("no se guardo")
      })
      //alert('termino');
    }
  }
  if (open) {
    const pages = survey.map(p => {
      return <Page key={p.num} page={p} viewCompress={true} hiddenButtonNext={true} />
    })
    return <div className="container mx-auto">

      {pages}
    </div>
  }
  return (
    <div className="container mx-auto">
      <div className="min-h-screen flex items-center">
        <Page page={page} next={() => next()} />
      </div>
    </div>

  )
}
