import './App.css';


function App() {
  window.open("https://kualy.ai", "_top");
  return (
    <div>
      Kualy
    </div>

  );
}

export default App;
