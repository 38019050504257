import axios, { AxiosError, HttpStatusCode } from "axios";
import { configrationApp } from "../configs/configurationApp";
import { StorageService } from "../services/Storage.service";




//let instance: AdminService;
export class AxiosService {
  private readonly BASE_API = configrationApp().apiApp;
  private static instance: AxiosService;

  constructor() {
    if (AxiosService.instance) {
      throw new Error("You can only create one instance!");
    }
    AxiosService.instance = this;
    this._init();
  }
  static getInstance() {
    if (!AxiosService.instance) {
      AxiosService.instance = new AxiosService();
    }

    return AxiosService.instance;
  }
  private _init(): void {
    axios.defaults.baseURL = this.BASE_API;
    axios.interceptors.request.use((config) => {
      const survey = StorageService.getInstance().getLocal('survey');
      const workspace = StorageService.getInstance().getLocal('workspace');
      const headers: any = {
        ...config.headers,
        survey, workspace
      };
      config.headers = headers;
      return config;
    })
    axios.interceptors.response.use(function (response) {
      return response.data;
    }, function (error: AxiosError) {
      if (error.response?.status === HttpStatusCode.FailedDependency) {
        alert("No tiene DB asignada");

      }
      console.log('error', error.response?.status);

      return Promise.reject(error);
    });
  }
}