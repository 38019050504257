import { Typography } from "@mui/material";

export default function Thank() {
  console.log('sss');

  return (
    <div className="min-h-screen flex items-center">
      <div>
        <div>
          <img className="max-100" src="/images/logo_final.png" alt="" />
        </div>
        <div className="p-4 text-center">
          <Typography variant="h5">
            Muchas gracias por ser parte de la creación de nuestra cultura
          </Typography>
        </div>
      </div>
    </div>
  )
}
